<template>
  <footer
    class="flex flex-col items-center gap-8 p-8 border-t lg:p-4 lg:pr-11 lg:gap-15 lg:flex-row lg:place-content-end bg-iq-black border-t-white"
  >
    <a
      class="flex justify-center lg:justify-start gap-2 underline text-regular-10 text-iq-beige/75 lg:w-[60%]"
      href="https://iq-me.de"
      target="_blank"
    >
      <span
        >MADE WITH
        <Icon name="heroicons:heart-solid" class="w-3 h-3 text-iq-red/60" />
        BY IQ-ME</span
      >
    </a>

    <div v-for="(m, i) in menuItems" :key="i" class="menu-item-footer">
      <NuxtLink :to="m.link">{{ m.title }}</NuxtLink>
    </div>
  </footer>
</template>
<script lang="ts" setup>
const { t } = useI18n();

const menuItems = computed(() => [
  { link: '/imprint', title: t('imprint') },
  { link: '/privacy', title: t('privacy') },
]);
</script>
<i18n lang="json">
{
  "en": {
    "imprint": "IMPRINT",
    "privacy": "PRIVACY & TERMS"
  },
  "de": {
    "imprint": "IMPRESSUM",
    "privacy": "DATENSCHUTZ & AGB"
  }
}
</i18n>
