<template>
  <div
    class="flex flex-row justify-between w-full p-4 px-8 lg:hidden bg-iq-grey-dark"
  >
    <NuxtLink to="/">
      <NuxtImg
        src="/images/b2bscan-horizontal.svg"
        width="200px"
        height="160px"
        placeholder
        alt="B2BScan Logo"
        title="B2BScan Logo Horizontal"
      />
    </NuxtLink>
    <div class="flex self-center">
      <TheLanguageSwitcher
        class-txt="text-regular-20 menu-item-header"
        class="mx-4"
        open-direction="bottom"
      />
      <NuxtLink
        v-highlight-index="3"
        to="/login"
        class="text-regular-20 menu-item-header"
        >LOGIN</NuxtLink
      >
    </div>
  </div>
  <TheHeaderBar class="fixed z-10 hidden lg:flex bg-iq-grey-dark">
    <div class="top-0 flex flex-row items-center justify-between w-full mx-4">
      <NuxtLink to="/">
        <NuxtImg
          src="/images/b2bscan-horizontal.svg"
          width="200px"
          height="80px"
          class="py-2"
          alt="B2BScan Logo"
          title="B2BScan Logo Horizontal"
          placeholder
        />
      </NuxtLink>
      <div
        v-if="isNotLoginPage"
        class="flex flex-row flex-1 mx-[10%] justify-evenly"
      >
        <div
          v-for="(m, index) in menuItems"
          :key="index"
          class="mr-6 menu-item-header !iq-hover"
          :class="{ '!text-iq-red': activeId === m.pageId.slice(1) }"
          @click="scrollToSection(m.sectionId)"
        >
          {{ m.name }}
        </div>
      </div>

      <div class="flex self-center">
        <NuxtLink
          v-highlight-index="3"
          to="/login"
          class="text-regular-20 hover:!text-iq-red !tracking-iq-wide menu-item-header"
          >LOGIN</NuxtLink
        >
        <TheLanguageSwitcher
          class-txt="text-regular-20 !tracking-iq-wide menu-item-header"
          class="ml-8 !tracking-iq-wide"
          open-direction="bottom"
        />
      </div>
    </div>
  </TheHeaderBar>
</template>
<script lang="ts" setup>
const router = useRouter();
// import imgLogoServer from '~/assets/images/b2bscan-horizontal.svg';
// const config = useRuntimeConfig();
// const imgLogo = config.public.isProd
//   ? imgLogoServer
//   : '../public/icons/b2bscan-horizontal.svg';

const { t } = useI18n();

const isNotLoginPage = computed(
  () =>
    router.currentRoute.value.path !== '/login' &&
    router.currentRoute.value.path !== '/imprint' &&
    router.currentRoute.value.path !== '/privacy'
);

async function scrollToSection(sectionId: string) {
  if (!isNotLoginPage.value) {
    await navigateTo('/');
    return;
  }

  const element = document.querySelector(sectionId);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const menuItems = computed(() => [
  {
    name: t('scan'),
    sectionId: '#section-scan',
    pageId: '#scan',
  },
  {
    name: t('features'),
    sectionId: '#section-features',
    pageId: '#features',
  },
  {
    name: t('pricing'),
    sectionId: '#section-pricing',
    pageId: '#pricing',
  },
  {
    name: t('about_us'),
    sectionId: '#section-about-us',
    pageId: '#about-us',
  },
  {
    name: t('contact'),
    sectionId: '#section-contact',
    pageId: '#contact',
  },
]);

const activeId = ref('');

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          activeId.value = entry.target.id;
        }
      });
    },
    {
      rootMargin: '0px',
      threshold: 0.6,
    }
  );
  const items = [...menuItems.value, { pageId: '#scan' }];
  items.forEach((item) => {
    const element = document.querySelector(item.pageId);
    if (element) observer.observe(element);
  });

  onUnmounted(() => {
    observer.disconnect();
  });
});
</script>
<i18n lang="json">
{
  "en": {
    "scan": "SCAN",
    "features": "FEATURES",
    "pricing": "PRICING",
    "about_us": "ABOUT US",
    "contact": "CONTACT"
  },
  "de": {
    "scan": "SCAN",
    "features": "FEATURES",
    "pricing": "PREISE",
    "about_us": "ÜBER UNS",
    "contact": "KONTAKT"
  }
}
</i18n>
