<template>
  <div
    class="max-h-[100dvh] min-h-[100dvh] relative flex flex-col justify-between"
  >
    <TheLandingPageHeader />
    <div class="dark">
      <slot />
    </div>
    <TheLandingPageFooter />
  </div>
</template>

<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: 'dark',
  },
});
</script>
